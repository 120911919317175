<!-- 库存盘存 -->
<template>
  <div class="page">
    <div class="topDiv">
      <Form inline class="formMarginBtm20 clearfix">
        <!-- 盘存人 -->
        <FormItem>
          <span>盘存人：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.create_by" clearable filterable>
            <Option v-for="item in handledByList" :value="item.id" :key="item.id">{{ item.user_name }}</Option>
          </Select>
        </FormItem>
        <!-- 盘存仓库 -->
        <FormItem class="marginLeft40">
          <span>盘存仓库：</span>
          <Select class="iviewIptWidth250" v-model="queryFrom.warehouse_id" filterable clearable>
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <!-- 盘存时间 -->
        <FormItem class="marginLeft40">
          <span>盘存时间：</span>
          <DatePicker :editable="false" type="daterange" placeholder="请选择" format="yyyy-MM-dd" class="iviewIptWidth250" @on-change="changeTime"></DatePicker>
        </FormItem>
        <FormItem class="po-create-number marginRight0" :label-width="10">
          <span class="pageBtn finger btnSure" @click="query">查询</span>
          <span class="pageBtn finger btnReset marginLeft20" @click="add">新增盘存</span>
        </FormItem>
      </Form>
    </div>

    <ul class="topNavDiv">
      <li v-for="(item, index) in navFrist" :key="index" :class="{ clickActive: clickIndex == item.type, finger: true }" @click="clickNav(item.type)">
        {{ item.name }}
        <div class="linear" v-if="clickIndex == item.type"></div>
      </li>
    </ul>
    <div class="tabelDiv">
      <Table :productList="clickIndex == 1 ? listColumns : listColumns2" :productData="listData" :total="total" :pages="queryFrom" @change-page="changePage" totalText="条记录" :loading="isLoad" :isLoad="isLoad">
        <!--      <div slot="summary" class="summary" v-if="listData.length > 0">-->
        <!--        总盈亏：<span style="color: #FA3E3B" v-if="total_profit_loss >= 0">{{ total_profit_loss }}</span><span style="color: #2EA909" v-else>{{ total_profit_loss }}</span>-->
        <!--      </div>-->
      </Table>
    </div>
  </div>
</template>

<script>
import Table from '@/components/table'
export default {
  name: 'inventoryStock',
  components: {
    Table,
  },
  data() {
    return {
      isLoad: true,
      clickIndex: 1,
      // 仓库盘存tab
      navFrist: [
        {
          name: '盘存记录',
          type: 1,
          num: 0,
        },
        {
          name: '报溢/报损记录',
          type: 2,
          num: 0,
        },
      ],
      // 表格数据
      listData: [],
      // 表格头部标题
      listColumns: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '盘存时间',
          key: 'creatTime',
          align: 'center',
          width: 150,
        },
        {
          title: '盘存仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 270,
        },
        {
          title: '盘存人',
          key: 'user_name',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '库存数量',
          key: 'total_number',
          align: 'center',
          width: 130,
        },
        {
          title: '实际数量',
          key: 'check_quantity',
          align: 'center',
          width: 130,
        },
        {
          title: '盘盈',
          width: 150,
          align: 'center',
          render: (h, param) => {
            // if (param.row.wins_quantity > 0) {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#FA3E3B',
                  },
                },
                param.row.wins_quantity
              ),
            ])
            // } else {
            //   return h('div', [
            //     h(
            //       'span',
            //       {
            //         style: {
            //           color: '#2EA909',
            //         },
            //       },
            //       param.row.wins_quantity
            //     ),
            //   ])
            // }
          },
        },
        {
          title: '盘亏',
          width: 150,
          key: 'loss_quantity',
          align: 'center',
          render: (h, param) => {
            // if (param.row.loss_quantity > 0) {
            //   return h('div', [
            //     h(
            //       'span',
            //       {
            //         style: {
            //           color: '##2EA909',
            //         },
            //       },
            //       param.row.loss_quantity
            //     ),
            //   ])
            // } else {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#FA3E3B',
                  },
                },
                param.row.loss_quantity
              ),
            ])
            // }
          },
        },
        {
          title: '盘存结果',
          key: 'result',
          align: 'center',
          width: 130,
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',

                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                '详情'
              ),
            ])
          },
        },
      ],
      // 报溢报损记录表头
      listColumns2: [
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 65,
        },
        {
          title: '单据编号',
          key: 'spillover_order_number',
          align: 'center',
          width: 170,
        },
        {
          title: '单据类型',
          key: 'order_type_str',
          align: 'center',
          width: 150,
        },
        {
          title: '盘存时间',
          key: 'create_time',
          align: 'center',
          width: 150,
        },
        {
          title: '盘存仓库',
          key: 'warehouse_name',
          align: 'center',
          width: 270,
        },
        {
          title: '盘存人',
          key: 'create_user',
          align: 'center',
          minWidth: 150,
        },
        {
          title: '数量',
          key: 'total_number',
          align: 'center',
          width: 130,
        },
        {
          title: '状态',
          key: 'status_str',
          align: 'center',
          width: 130,
        },
        {
          title: '操作',
          align: 'center',
          width: 189,
          render: (h, param) => {
            return h('div', [
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    display: param.row.status == 2 ? 'block' : 'none',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goDetails(param.row)
                    },
                  },
                },
                '详情'
              ),
              h(
                'span',
                {
                  style: {
                    color: '#389AFC',
                    display: param.row.status == 0 ? 'block' : 'none',
                    cursor: 'pointer',
                  },
                  on: {
                    click: () => {
                      this.goSpilloverOrderManage(param.row)
                    },
                  },
                },
                '审核'
              ),
            ])
          },
        },
      ],
      // 表单拉下选项
      storeList: [],
      handledByList: [],
      queryFrom: {
        page: 1,
        rows: 10,
        create_by: '',
        warehouse_id: '',
        create_time_start: '',
        create_time_end: '',
      },
      total: 0,
      total_profit_loss: 0,
    }
  },
  activated() {
    if (sessionStorage.getItem('updataCache') == 0) {
      this.query()
      sessionStorage.setItem('updataCache', '1')
    }
  },
  mounted() {
    this.queryStoreList()
    this.query()
    this.querySpecialUser()
  },
  methods: {
    // tab事件
    clickNav(type) {
      this.queryFrom.page = 1
      this.clickIndex = type
      if (this.clickIndex == 1) {
        this.queryList()
      } else if (this.clickIndex == 2) {
        this.getOverflowandLossReporting()
      }
    },
    // 获取报溢/报损记录
    getOverflowandLossReporting() {
      this.isLoad = true
      this.$http.get(this.$api.SpilloverOrderManage, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.navFrist[1].num = res.total
        this.total_profit_loss = res.total_profit_loss
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].create_time = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD') : ''
        }
        this.$forceUpdate()
      })
    },
    // 时间改变
    changeTime(e) {
      if (e[0] == '' && e[1] == '') {
        this.queryFrom.create_time_start = null
        this.queryFrom.create_time_end = null
      } else {
        this.queryFrom.create_time_start = e[0]
        this.queryFrom.create_time_end = e[1]
      }
    },
    // 查询经手人和审核人
    querySpecialUser() {
      this.$http.get(this.$api.publicSpecialUser, { user_type: 3 }, true).then(res => {
        this.handledByList = res.data
      })
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
      }
      this.$http.get(this.$api.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    changePage(e) {
      this.queryFrom.page = e
      if (this.clickIndex == 1) {
        this.queryList()
      } else {
        this.getOverflowandLossReporting()
      }
    },
    // 点击查询按钮
    query() {
      this.queryFrom.page = 1
      if (this.clickIndex == 1) {
        this.queryList()
      } else {
        this.getOverflowandLossReporting()
      }
    },
    // 查询列表数据
    queryList() {
      this.isLoad = true
      this.$http.get(this.$api.queryInventory, this.queryFrom, true).then(res => {
        this.isLoad = false
        this.listData = res.data
        this.total = res.total
        this.navFrist[0].num = res.total
        this.total_profit_loss = res.total_profit_loss
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].creatTime = res.data[i].create_time ? this.$moment(res.data[i].create_time * 1000).format('YYYY-MM-DD') : ''
        }
      })
    },
    // 新增盘存
    add() {
      this.$router.push({
        path: '/addInventoryStock',
      })
    },
    // 详情
    goDetails(row) {
      // 判断是盘存单还是报溢报损单
      let id = null
      let order_type = 0
      if (this.clickIndex == 1) {
        id = row.id
      } else if (this.clickIndex == 2) {
        id = row.spillover_order_number
        order_type = row.order_type
      }
      this.$router.push({
        path: '/inventoryStockDetail',
        query: {
          id: id,

          type: this.clickIndex, // 1 = 盘存记录  2 = 报溢报损
          order_type: order_type, // 报溢报损
        },
      })
    },
    // 报溢报损审核
    goSpilloverOrderManage(row) {
      this.$router.push({
        path: '/inventoryStockToExamins',
        query: {
          id: row.spillover_order_number,
          order_type: row.order_type,
        },
      })
    },
  },
}
</script>

<style scoped lang="less">
.page {
  background: #f0f0f0 !important;
  display: flex;
  flex-direction: column;
  .tabelDiv {
    flex: 1;
    background: #fff;
    border-radius: 0 8px 8px 8px;
    padding: 20px 25px;
  }
  .topDiv {
    padding: 20px 25px 0 25px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 20px;
    .form {
      .label {
        color: #000;
      }
      /deep/ .ivu-select-dropdown {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  .topNavDiv {
    height: 42px;
    li {
      float: left;
      height: 42px;
      padding: 0px 10px;
      line-height: 42px;
      text-align: center;
      margin-right: 2px;
      background: #e2e2e2;
      color: #999999;
      font-size: 15px;
      border-radius: 3px 3px 0 0;
      position: relative;
      box-sizing: border-box;
    }
    .clickActive {
      background: #fff;
      color: #008cf8;
      font-weight: bold;
    }
  }
}
.marginRight70 {
  margin-right: 70px;
}
.marginRight90 {
  margin-right: 90px;
}
.centeFont {
  margin: 0 10px;
}
/deep/ .marginLeft30 {
  margin-left: 15px;
}
/deep/ .disableUse {
  display: none;
  position: absolute;
}
/deep/ .ivu-table-wrapper {
  overflow: initial !important;
}
</style>
